import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Ironsource Search Application",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:51:40.000Z",
  "url": "/2018/02/ironsource-search-application/",
  "categories": ["Uncategorized"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`{.content-preview-title}`}</h1>
    <p>{`Client:Irosource`}</p>
    <p>{`Date:02/2015`}</p>
    <p>{`Info:Search Engine Product`}</p>
    <p>{`Launch Project`}</p>
    <p>{`Full featured search web application, provides all the functionality of top ranked search engines.`}</p>
    <p>{`This application was served to milions of users, of course it had responsive design, and many themes as it supported several `}{`“`}{`whitelabels`}{`”`}{`.`}</p>
    <p>{`The product used several apis to major search providers, ad providers and more.`}</p>
    <p>{`Each step of the user inside the application was logged for future inspecting, actions like: landing, searching, second search, organic click, ad click, duration etc.`}</p>
    <p>{`The technology stack was:`}</p>
    <p>{`backend: php`}</p>
    <p>{`DB: mysql, redis`}</p>
    <p>{`frontend: javascript, jquery, html, css`}</p>
    <p>{`automation & deployment: fabric`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      